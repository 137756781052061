<template>
  <div
    class="border rounded px-2 py-1 d-flex justify-content-between align-items-center position-relative bg-white"
  >
    <b-input
      v-if="visible === true"
      type="number"
      v-model="amount"
      @blur="onBlurNumber"
      class="input-style"
    />
    <b-input
      v-if="visible === false"
      type="text"
      v-model="amount"
      @focus="onFocusText"
      class="input-style"
    />
  </div>
</template>

<script>
import { store } from "@/store/global.js";
export default {
  props: [
    "data",
    "place",
    "keyIndex",
    "person",
    "keyname",
    "maxdata",
    "isSingleCheck",
  ],
  data() {
    return {
      amount: null,
      temp: null,
      visible: false,
    };
  },
  computed: {
    semester() {
       return store.semester;
    },
  },
  created() {},
  methods: {
    onBlurNumber(e) {
      //console.log(this.semester)
      this.visible = false;
      let amount, isUp;
      if (this.isSingleCheck) {
        ({ amount, isUp } = this.checkPrice3(+this.amount));
      } else {
        if (this.semester == 1) {
          ({ amount, isUp } = this.checkPrice(+this.amount));
        } else if (this.semester == 2) {
          ({ amount, isUp } = this.checkPrice2(+this.amount));
        }
      }

      this.temp = amount;
      this.amount = this.thousandSeprator(+amount);

      this.amount =
        !Boolean(this.amount) || this.amount == "null" ? 0 : this.amount;
      let obj = {};
      if (this.isSingleCheck) {
        obj = {
          data: amount,
          keyname: this.keyname,
          isUp,
        };
      } else {
        obj = {
          data: amount,
          place: this.place,
          keyIndex: this.keyIndex,
          isUp,
        };
      }

      this.$emit("update", obj);
    },
    onFocusText() {
      this.visible = true;
      this.amount = this.temp;
      this.amount = `${this.data}`.includes(",")
        ? `${this.data}`.replace(/,/g, "")
        : +this.data;
    },
    thousandSeprator(amount) {
      if (
        amount !== "" ||
        amount !== undefined ||
        amount !== 0 ||
        amount !== "0" ||
        amount !== null
      ) {
        return `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    checkPrice(total) {
      let upperArr = [...store.uppPriceArr];
      //1.find upper
      let upper = "";
      switch (this.place) {
        case "Fee_1":
          upper = upperArr.find((s) => s.MajorName == this.person.MajorId)
            ? upperArr.find((s) => s.MajorName == this.person.MajorId).Fee1
            : "";
          break;
        case "Fee_2":
          upper = upperArr.find((s) => s.MajorName == this.person.MajorId)
            ? upperArr.find((s) => s.MajorName == this.person.MajorId).Fee2
            : "";
          break;
        case "Fee_3":
          upper = upperArr.find((s) => s.MajorName == this.person.MajorId)
            ? upperArr.find((s) => s.MajorName == this.person.MajorId).Fee3
            : "";
          break;
        case "Fee_10":
          upper = 5000;

          break;
        case "Fee_11":
          upper = 18000;

          break;
        case "Fee_12":
          upper =
            this.person.MajorId == "醫學系" || this.person.MajorId == "牙醫學系"
              ? 30000
              : 15000;
          break;
        default:
          console.log("no data");
      }
      const totalUper = +upper * this.person.NumberOfPeople;
      if (+total > totalUper) {
        return {
          amount: totalUper,
          isUp: true,
        };
      } else {
        return {
          amount: total,
          isUp: false,
        };
      }
    },
    checkPrice2(total) {
      let upperArr = [...store.uppPriceArr];
      //1.find upper
      let upper = "";
      switch (this.place) {
        case "Fee_1":
          upper = upperArr.find((s) => s.MajorName == this.person.MajorName)
            ? upperArr.find((s) => s.MajorName == this.person.MajorName).Fee1
            : "";
          break;
        case "Fee_2":
          upper = upperArr.find((s) => s.MajorName == this.person.MajorName)
            ? upperArr.find((s) => s.MajorName == this.person.MajorName).Fee2
            : "";
          break;
        case "Fee_3":
          upper = upperArr.find((s) => s.MajorName == this.person.MajorName)
            ? upperArr.find((s) => s.MajorName == this.person.MajorName).Fee3
            : "";
          break;
        case "Fee_9":
          upper = 3000;

          break;
        case "Fee_10":
          upper = 5000;

          break;
        case "Fee_11":
          upper = 18000;

          break;
        default:
          console.log("no data");
      }
      const totalUper = +upper;
      if (+total > totalUper) {
        return {
          amount: totalUper,
          isUp: true,
        };
      } else {
        return {
          amount: total,
          isUp: false,
        };
      }
    },
    checkPrice3(total) {
      if (+total > this.maxdata) {
        return {
          amount: +this.maxdata,
          isUp: true,
        };
      } else {
        return {
          amount: +total,
          isUp: false,
        };
      }
    },
  },

  mounted() {
    requestAnimationFrame(() => {
      this.amount =
        this.data == null
          ? 0
          : `${this.data}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    });
  },
};
</script>

<style lang="scss" scoped>
.input-style {
  height: 43px;
  border: 1px solid black;
  text-align: end;
  padding-right: 5px;
}
</style>
